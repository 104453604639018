<template>
    <div>
      <el-row class="bgW">
        <el-col :span="18">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">

             <el-form-item label="日期">
              <el-date-picker
                v-model="search.start_time"
                type="date"
                class="w130"
                placeholder="开始日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
              <el-date-picker
                v-model="search.end_time"
                type="date"
                class="w130"
                placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="门店类型" prop="type">
              <el-select
                v-model="search.type"
                class="w100"
                clearable
                placeholder="门店类型"
              >
                <el-option
                  v-for="item in options.type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>

          </el-form>
        </el-col>
        <el-col :span="6" class="btnBox">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
          <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button>
        </el-col>
        </el-row>
      <div>
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              :data="tableData"
              size="small"
              border
              max-height="550"
              v-loading="loading"
              :span-method="objectSpanMethod"
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
            >
              <ElTableColumn label="市场" prop="market_name" fixed/>
              <ElTableColumn label="门店" prop="shop_name" fixed/>
              <ElTableColumn label="进店数量明细" :render-header="renderHeader">
                <el-table-column label="疤痕" prop="bh_store"></el-table-column>
                <el-table-column label="痘痘" prop="dd_store"></el-table-column>
                <el-table-column label="项目" prop="xm_store"></el-table-column>
                <el-table-column label="总进店" prop="store_sum"></el-table-column>
              </ElTableColumn>
              <ElTableColumn label="体验数量" :render-header="renderHeader">
                <el-table-column label="疤痕" prop="bh_receive"></el-table-column>
                <el-table-column label="痘痘" prop="dd_receive"></el-table-column>
                <el-table-column label="项目" prop="xm_receive"></el-table-column>
                <el-table-column label="合计" prop="receive_sum"></el-table-column>
              </ElTableColumn>
              <ElTableColumn label="体验成交数量" :render-header="renderHeader">
                <el-table-column label="疤痕" prop="bh_receive_deal"></el-table-column>
                <el-table-column label="痘痘" prop="dd_receive_deal"></el-table-column>
                <el-table-column label="项目" prop="xm_receive_deal"></el-table-column>
                <el-table-column label="合计" prop="receive_deal_sum"></el-table-column>
              </ElTableColumn>
              <ElTableColumn label="体验成交金额" :render-header="renderHeader">
                <el-table-column label="疤痕" prop="bh_receive_money"></el-table-column>
                <el-table-column label="痘痘" prop="dd_receive_money"></el-table-column>
                <el-table-column label="项目" prop="xm_receive_money"></el-table-column>
                <el-table-column label="合计" prop="receive_money_sum"></el-table-column>
              </ElTableColumn>
              <ElTableColumn label="直接成交数量" :render-header="renderHeader">
                <el-table-column label="疤痕" prop="bh_direct"></el-table-column>
                <el-table-column label="痘痘" prop="dd_direct"></el-table-column>
                <el-table-column label="项目" prop="xm_direct"></el-table-column>
                <el-table-column label="合计" prop="direct_sum"></el-table-column>
              </ElTableColumn>
              <ElTableColumn label="直接成交金额" :render-header="renderHeader">
                <el-table-column label="疤痕" prop="bh_direct_money"></el-table-column>
                <el-table-column label="痘痘" prop="dd_direct_money"></el-table-column>
                <el-table-column label="项目" prop="xm_direct_money"></el-table-column>
                <el-table-column label="合计" prop="direct_money_sum"></el-table-column>
              </ElTableColumn>
              <ElTableColumn label="新客成交总额" prop="money_sum" />
              <ElTableColumn label="疤痕成交率" prop="bh_rate" />
              <ElTableColumn label="痘痘成交率" prop="dd_rate" />
              <ElTableColumn label="项目成交率" prop="xm_rate" />

            </el-table>
            <!-- <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            /> -->
          </el-col>
        </el-row>
      </div>
      <!-- 新增弹框 -->

    </div>
  </template>

  <script>
  import { getLastDay } from '@/utils';
  import { getAnalysis } from "@/api/statistics";
  import { handleExportExcel } from '@/vendor/handleExportExcel';

  class Search {
    start_time = "";
    end_time = "";
    type = "";
  }
  class Options {
    type = [
        {
          id: "1",
          name: "直营"
        },
        {
          id: "2",
          name: "加盟"
        },
        {
          id: "3",
          name: "直管"
        }
      ];
  }
  export default {
  name: 'OrderList',
  components: {
    // pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      options: new Options(), //选项
      list: [], // 数据展示
      taskFrame: false,
      loading: false,
      exportS: false,
      time: '',
      loading: false,
    }
  },
computed: {
    tableData() {
      const res = this.list.reduce((res, item) => {
        item.maxnum = 0;
        if (item.list && item.list.length > 0) {
          const goods = item.list.map(goods => Object.assign({}, item, goods));
          goods[0].maxnum = item.list.length;
          res = res.concat(goods);
        } else {
          res.push(item);
        }
        return res;
      }, []);
      return res;
    }
  },
  mounted () {
    let year = new Date().getFullYear()
    let month = new Date().getMonth() + 1
    if(month.toString().length == 1){
      month = '0'+ month
    }
    let day = getLastDay(year,month)
    if(day.toString().length == 1){
      day = '0'+ day
    }
    this.search.start_time = year+'-'+ month + '-01'
    this.search.end_time = year+'-'+ month + '-' + day
    this.getList('search');
  },
  methods: {
    getList(option) {
      this.loading = true
      // if (option === "search") this.page = new Page();
      getAnalysis({  ...this.search }).then(res => {
        this.list = res.data;
        this.loading = false;
        console.log(res);
      });
    },
    // 导出表格
    async exportExcel() {
      this.exportS = true
      const { data } = await getAnalysis({
         ...this.search,export:1
      });
      handleExportExcel(
        [
          '市场名称',
          '店面名称',

          '疤痕进店',
          '痘痘进店',
          '项目进店',
          '总进店',

          '疤痕体验数',
          '痘痘体验数',
          '项目体验数',
          '总体验数',

          '疤痕体验成交数',
          '痘痘体验成交数',
          '项目体验成交数',
          '总体验成交数',

          '疤痕体验成交金额',
          '痘痘体验成交金额',
          '项目体验成交金额',
          '总体验成交金额',

          '疤痕直接成交数',
          '痘痘直接成交数',
          '项目直接成交数',
          '总直接成交数',

          '疤痕直接成交金额',
          '痘痘直接成交金额',
          '项目直接成交金额',
          '总直接成交金额',

          '新客成交总额',
          '疤痕成交率',
          '痘痘成交率',
          '项目成交率',
        ],
        [
          'market_name',
          'shop_name',
          'bh_store',
          'dd_store',
          'xm_store',
          'store_sum',

          'bh_receive',
          'dd_receive',
          'xm_receive',
          'receive_sum',

          'bh_receive_deal',
          'dd_receive_deal',
          'xm_receive_deal',
          'receive_deal_sum',

          'bh_receive_money',
          'dd_receive_money',
          'xm_receive_money',
          'receive_money_sum',

          'bh_direct',
          'dd_direct',
          'xm_direct',
          'direct_sum',

          'bh_direct_money',
          'dd_direct_money',
          'xm_direct_money',
          'direct_money_sum',

          'money_sum',
          'bh_rate',
          'dd_rate',
          'xm_rate',
        ],
        data,
        '新客统计'
      );
      this.exportS = false
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          rowspan: row.maxnum,
          colspan: 1
        };
      } else {
        return {
          rowspan: 1,
          colspan: 1
        };
      }
    },
    renderHeader(h, {column}) {
      switch(column.label)
      {
        case "进店数量明细":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '进店数量明细：按照客户的疤痕 青春痘 项目进店数据进行统计',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "体验数量":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '体验数量：按照体验面部|体验身体|体验痘痘|体验疤痕的服务核销记录统计客户数',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "体验成交数量":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '体验成交数量:体验人数中有成交新单（首次进店成交金额大于等于500以上的收款新单）的客户数',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "体验成交金额":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '体验成交金额:体验人数中有成交新单（首次进店成交金额大于等于500以上的收款新单）在本月所有的收款合计减去 退款合计',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "直接成交数量":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '直接成交数量',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
        case "直接成交金额":{
          return h(
          'div', [
              h('el-tooltip', {
                  props: {
                      effect: 'dark',
                      content: '直接成交金额',
                      placement: 'top',
                  },
              },
              [h('span',  column.label)])
          ]
          )
        }
      }
    },
  }
}
  </script>

  <style lang="scss" scoped>
  .footerBtn,.btnBox {
    text-align: right;
  }
  </style>
